import Spinner from "components/Spinner";
import { useAuditLog } from "features/auditLog/api/useAuditLog";
import AuditLog from "features/courses/components/AuditLog";
import AppHeader from "features/header/components/AppHeader";
import { useAllProducts } from "features/products/api/useProducts";
import { useSections } from "features/sections/api/useSections";

const AuditLogPage = () => {
  const { data, isLoading } = useAuditLog();

  const { data: sections } = useSections();
  const { data: products } = useAllProducts();

  if (!data || isLoading) {
    return (
      <>
        <AppHeader />
        <Spinner />
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <AuditLog auditLog={data} sections={sections} products={products} />
    </>
  );
};

export default AuditLogPage;
