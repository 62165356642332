import { useTreeContext } from "features/courses/context/TreeContext";
import TreeNode from "./TreeNode";

type SidebarTreeViewProps = {
  onSelect: (id: string) => void;
};
const SidebarTreeView = ({ onSelect }: SidebarTreeViewProps) => {
  const { treeData } = useTreeContext();
  return (
    <div className="p-2 text-sm">
      {treeData.map((node) => (
        <TreeNode key={node.id} node={node} onSelect={onSelect} />
      ))}
    </div>
  );
};

export default SidebarTreeView;
