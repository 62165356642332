import { Input } from "@headlessui/react";
import { useState, useMemo } from "react";

export type MenuAuditLogTableProps = {
  auditLogs: any[];
  metadata: Record<string, any>;
  onSelect: (id: string) => void;
};

export const MenuAuditLogTable = ({ auditLogs, metadata, onSelect }: MenuAuditLogTableProps) => {
  const [search, setSearch] = useState("");

  const filteredChanges = useMemo(() => {
    return auditLogs
      .flatMap((log) =>
        log.changes.map((change: any) => ({
          ...change,
          externalId: log.externalId,
          entityType: log.entityName,
          meta: metadata[log.externalId] || {},
        })),
      )
      .filter((entry) => {
        const text =
          `${entry.meta.displayName || ""} ${entry.propertyName || ""} ${entry.entityType || ""} ${entry.oldValue || ""} ${entry.newValue || ""} ${entry.username}`.toLowerCase();
        return text.includes(search.toLowerCase());
      })
      .sort((a, b) => new Date(b.timestampUtc).getTime() - new Date(a.timestampUtc).getTime());
  }, [auditLogs, metadata, search]);

  return (
    <div className="space-y-4">
      <Input placeholder="Search changes..." value={search} onChange={(e) => setSearch(e.target.value)} />
      <div className="overflow-x-auto">
        <table className="min-w-full text-sm text-left">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-3 py-2">Entity</th>
              <th className="px-3 py-2">Entity</th>
              <th className="px-3 py-2">Type</th>
              <th className="px-3 py-2">Field</th>
              <th className="px-3 py-2">Old</th>
              <th className="px-3 py-2">New</th>
              <th className="px-3 py-2">User</th>
              <th className="px-3 py-2">Time</th>
            </tr>
          </thead>
          <tbody>
            {filteredChanges.map((entry, i) => (
              <tr key={i} className="border-t border-gray-200">
                <td className="px-3 py-2 whitespace-nowrap">
                  <span className="hover:font-semibold cursor-pointer" onClick={() => onSelect(entry.externalId)}>
                    {entry.meta.displayName || entry.externalId}
                  </span>
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-gray-500">{entry.entityType}</td>
                <td className="px-3 py-2 whitespace-nowrap">{entry.propertyName || "-"}</td>
                <td className="px-3 py-2 whitespace-nowrap text-gray-500">{entry.oldValue}</td>
                <td className="px-3 py-2 whitespace-nowrap text-gray-800 font-medium">{entry.newValue}</td>
                <td className="px-3 py-2 whitespace-nowrap">{entry.username}</td>
                <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{new Date(entry.timestampUtc).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredChanges.length === 0 && <div className="text-sm text-gray-500 italic p-4">No changes match your search.</div>}
      </div>
    </div>
  );
};

export default MenuAuditLogTable;
