import { ChevronDownIcon, ChevronRightIcon } from "@ordr-as/icons";
import { useTreeContext } from "features/courses/context/TreeContext";
import { cn } from "features/courses/utils/treeUtils";
import { useCallback, useEffect } from "react";

export type TreeNodeData = {
  id: string;
  name: string;
  children?: TreeNodeData[];
  parent?: TreeNodeData;
};

export type TreeNodeProps = {
  node: TreeNodeData;
  level?: number;
  onSelect: (id: string) => void;
};

const TreeNode = ({ node, level = 0, onSelect }: TreeNodeProps) => {
  const { selectedId, expandedIds, setExpandedIds, refMap } = useTreeContext();

  const hasChildren = node.children && node.children.length > 0;
  const isExpanded = expandedIds.includes(node.id);
  const isSelected = selectedId === node.id;

  console.log(expandedIds);

  useEffect(() => {
    if (isSelected) {
      refMap[node.id]?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }, [isSelected, node.id, refMap]);

  const handleExpand = useCallback(() => {
    if (!isExpanded) {
      console.log("Expanding node:", node.id);
      setExpandedIds((prev) => [...prev, node.id]);
    }
  }, [isExpanded, node.id, setExpandedIds]);

  const handleCollapse = useCallback(() => {
    if (hasChildren) {
      setExpandedIds((prev) => prev.filter((id) => id !== node.id));
    }
  }, [hasChildren, node.id, setExpandedIds]);

  const handleClick = () => {
    handleExpand();
    onSelect(node.id);
  };

  return (
    <div ref={refMap[node.id]}>
      <div
        className={cn(
          "flex items-center cursor-pointer px-2 py-1 rounded hover:bg-gray-100",
          isSelected && "bg-blue-100 font-semibold text-blue-700",
        )}
        style={{ paddingLeft: `${level * 1.25}rem` }}
        onClick={handleClick}
      >
        {hasChildren ? (
          isExpanded ? (
            <ChevronDownIcon onClick={handleCollapse} className="w-4 h-4 mr-1" />
          ) : (
            <ChevronRightIcon onClick={handleExpand} className="w-4 h-4 mr-1" />
          )
        ) : (
          <span className="w-4 h-4 mr-1" />
        )}
        <span>{node.name}</span>
      </div>
      {isExpanded && hasChildren && (
        <div>
          {node.children!.map((child) => (
            <TreeNode key={child.id} node={child} level={level + 1} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode;
