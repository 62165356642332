import { ChangeLogMetaDto, MenuSectionDto, ProductOverviewDto } from "types/contracts/contracts";
import { TreeNodeData } from "../components/TreeView/TreeNode";

export const cn = (...classes: (string | false | null | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const buildTreeFromMenuSections = (sections: MenuSectionDto[], products: ProductOverviewDto[], menuId: string): TreeNodeData[] => {
  const productsGroupedBySectionId = products.reduce(
    (acc, product) => {
      if (!acc[product.sectionId]) {
        acc[product.sectionId] = [];
      }
      acc[product.sectionId].push(product);
      return acc;
    },
    {} as { [key: string]: ProductOverviewDto[] },
  );

  const treeData = sections.map((section) => ({
    id: section.sectionId,
    name: section.name,
    children: [
      {
        id: `${section.sectionId}-opening-hours`,
        name: "Opening hours",
        children: section.openingHours.map((hour) => ({
          id: hour.openingHoursId,
          name: `Dag ${hour.day}`,
        })),
      },
      {
        id: `${section.sectionId}-products`,
        name: "Products",
        children:
          productsGroupedBySectionId[section.sectionId]?.map((product) => ({
            id: product.productId,
            name: product.name,
          })) || [],
      },
    ],
  }));

  const rootNode = {
    id: menuId,
    name: "Menu",
    children: treeData,
  };

  return [rootNode];
};

export const buildTreeWithParentRefs = (nodes: TreeNodeData[]): TreeNodeData[] => {
  const setParents = (node: TreeNodeData, parent?: TreeNodeData) => {
    node.parent = parent;
    node.children?.forEach((child) => setParents(child, node));
  };
  nodes.forEach((node) => setParents(node));
  return nodes;
};

export const findNodeById = (nodes: TreeNodeData[], changes: { [key: string]: ChangeLogMetaDto }, id: string): TreeNodeData | null => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }

    if (node.children) {
      const found = findNodeById(node.children, changes, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
