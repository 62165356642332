import { useQuery } from "@tanstack/react-query";
import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { AuditLogDto } from "types/contracts/contracts";

export const useAuditLog = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const { selectedMenu } = useSelectedMenu();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/menus/${selectedMenu?.menuId}/audit-log`;

  const auditLogQuery = useQuery<AuditLogDto, Error>(["auditLog", selectedLocation?.locationId, selectedMenu?.menuId], () => get(url), {
    enabled: !!selectedLocation?.locationId && !!selectedMenu?.menuId,
    staleTime: 1000 * 60 * 30, // 30 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return auditLogQuery;
};
