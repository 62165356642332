import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useLookups } from "features/lookups/api/useLookups";
import EditProductFromProductListPage from "pages/EditProductFromProductListPage";
import EditProductFromMenuPage from "pages/EditProductFromMenuPage";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import BulkEditPage from "../BulkEditPage";
import LocationPage from "../LocationPage";
import MenuPage from "../MenuPage";
import OptionsPage from "../OptionsPage";
import ProductLibraryPage from "../ProductLibraryPage";
import SettingsPage from "../SettingsPage";
import SupplierPage from "../SupplierPage";
import TaxGroupsPage from "../TaxGroupsPage";
import WastageReportPage from "../WastageReportPage";
import CreateProductFromProductListPage from "pages/CreateProductFromProductListPage";
import CreateProductFromMenuPage from "pages/CreateProductFromMenuPage";
import { Userpilot } from "userpilot";
import config from "config";
import { useLocation } from "react-router";
import AuditLogPage from "pages/AuditLogPage";

const LocationRoutes = () => {
  const { data: lookups, isSuccess } = useLookups();
  const location = useLocation();
  const { selectedLocation } = useSelectedLocation();

  if (selectedLocation) {
    document.title = "Favrit Menu: " + selectedLocation.name;
  }

  useEffect(() => {
    if (isSuccess && selectedLocation) {
      const userpilotToken = config.env === "prod" ? "NX-f4b682c9" : "STG-NX-f4b682c9";
      Userpilot.initialize(userpilotToken);
      Userpilot.identify(lookups.username, {
        email: lookups.username,
        role: lookups.userIsAdmin ? "ADMIN" : "SUPER_USER",
        company: {
          id: selectedLocation.locationGroupId.toString(),
          locationId: selectedLocation.locationId.toString(),
          name: selectedLocation.name,
        },
      });
    }
  }, [isSuccess, lookups, selectedLocation]);

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<LocationPage />} />
      <Route path="/menus/:menuId" element={<MenuPage />} />
      <Route path="/menus/:menuId/sections/:sectionId" element={<MenuPage />} />
      <Route path="/menus/:menuId/sections/:sectionId/products/new" element={<CreateProductFromMenuPage />} />
      <Route path="/menus/:menuId/sections/:sectionId/products/:productId" element={<EditProductFromMenuPage />} />
      <Route path="/menus/:menuId/audit-log/:externalId?" element={<AuditLogPage />} />
      <Route path="/bulk" element={<BulkEditPage />} />
      <Route path="/bulk/products/new" element={<CreateProductFromProductListPage />} />
      <Route path="/bulk/products/:productId" element={<EditProductFromProductListPage />} />
      <Route path="/tax-groups" element={<TaxGroupsPage />} />
      <Route path="/options" element={<OptionsPage />} />
      <Route path="/options/:optionGroupId" element={<OptionsPage />} />
      <Route path="/product-library" element={<ProductLibraryPage />} />
      <Route path="/product-library/suppliers/:supplierId/" element={<SupplierPage />} />
      <Route path="/product-library/suppliers/:supplierId/products/:plProductId" element={<SupplierPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/wastage" element={<WastageReportPage />} />
    </Routes>
  );
};

export default LocationRoutes;
