import { useState, useMemo } from "react";
import { AuditLogDto, MenuSectionDto } from "types/contracts/contracts";

type ChangeLogDetailViewProps = {
  selectedId: string | null;
  auditLog: AuditLogDto;
  sections?: MenuSectionDto[];
  onSelect: (id: string) => void;
};
const ChangeLogDetailView = ({ selectedId, auditLog, onSelect }: ChangeLogDetailViewProps) => {
  const [filter, setFilter] = useState<string>("");

  const entries = useMemo(() => {
    return auditLog.changeLogs
      .filter((entry) => entry.externalId === selectedId)
      .flatMap((x) => x.changes)
      .sort((a, b) => new Date(b.timestampUtc).getTime() - new Date(a.timestampUtc).getTime());
  }, [selectedId, auditLog]);

  const metadata = auditLog.changeLogLookup;
  const meta = selectedId ? metadata[selectedId] : undefined;
  const title = meta?.displayName || "Unknown";
  const type = meta?.entityType || "Unknown type";

  const children = Object.entries(metadata)
    .filter(([, m]) => m.parentId === selectedId)
    .map(([id, m]) => ({ id, ...m }));

  const filteredEntries = entries.filter((entry) => {
    return filter === "" || entry.propertyName?.toLowerCase().includes(filter.toLowerCase());
  });

  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold">{title}</h2>
        <div className="text-sm text-gray-500">{type}</div>
      </div>

      <div>
        <input
          type="text"
          className="border px-2 py-1 rounded text-sm w-full"
          placeholder="Filter changes by property name..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      {filteredEntries.length > 0 && (
        <div>
          <div className="text-sm font-medium text-gray-700 mb-2">Changes for this item</div>
          <ul className="space-y-2">
            {filteredEntries.map((entry, idx) => (
              <li key={idx} className="border border-gray-200 rounded-md p-3 bg-white shadow-sm">
                <div className="flex justify-between text-sm mb-1 text-gray-600">
                  <span>{entry.propertyName || "State change"}</span>
                  <span>{new Date(entry.timestampUtc).toLocaleString()}</span>
                </div>
                <div className="text-sm">
                  {entry.entityState === "Modified" && (
                    <span>
                      <span className="font-medium text-gray-700">From:</span> <span className="text-red-600">{entry.oldValue}</span>{" "}
                      <span className="font-medium text-gray-700">To:</span> <span className="text-green-600">{entry.newValue}</span>
                    </span>
                  )}
                  {entry.entityState === "Added" && (
                    <div className="text-green-700">
                      New item added
                      {entry.payload && <pre className="bg-gray-50 text-xs mt-2 p-2 rounded border overflow-x-auto">{entry.payload}</pre>}
                    </div>
                  )}
                  {entry.entityState === "Deleted" && <span className="text-red-700">Item was deleted</span>}
                </div>
                <div className="text-xs text-gray-400 mt-1">User: {entry.username}</div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {children.length > 0 && (
        <div>
          <div className="text-sm font-medium text-gray-700 mt-6 mb-2">Changes in children</div>
          <ul className="space-y-4">
            {children.map((child) => {
              const childEntries = auditLog.changeLogs
                .filter((entry) => entry.externalId === child.id)
                .flatMap((x) => x.changes)
                .sort((a, b) => new Date(b.timestampUtc).getTime() - new Date(a.timestampUtc).getTime());

              return (
                <li key={child.id}>
                  <div className="text-sm font-semibold text-blue-700 cursor-pointer hover:underline" onClick={() => onSelect(child.id)}>
                    {child.displayName} ({child.entityType})
                  </div>
                  <ul className="mt-1 space-y-1">
                    {childEntries.map((entry, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        <span className="font-medium text-gray-800">{entry.propertyName || "State change"}:</span>{" "}
                        {entry.entityState === "Modified" && (
                          <>
                            <span className="text-red-600">{entry.oldValue}</span> → <span className="text-green-600">{entry.newValue}</span>
                          </>
                        )}
                        {entry.entityState === "Added" && "Added"}
                        {entry.entityState === "Deleted" && "Deleted"}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChangeLogDetailView;
