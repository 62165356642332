import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuditLogDto, MenuSectionDto, ProductOverviewDto } from "types/contracts/contracts";
import { TreeProvider, useTreeContext } from "../context/TreeContext";
import SidebarTreeView from "./TreeView/SidebarTreeView";
import MenuAuditLogTable from "./Details/MenuAuditLogTable";
import ChangeLogDetailView from "./Details/ChangeLogDetailView";
import { buildTreeFromMenuSections, buildTreeWithParentRefs, findNodeById } from "../utils/treeUtils";

type AuditLogContentProps = {
  auditLog: AuditLogDto;
  sections?: MenuSectionDto[];
  products?: ProductOverviewDto[];
  externalId: string | undefined;
};

const AuditLogContent = ({ auditLog, sections, externalId }: AuditLogContentProps) => {
  const { treeData, setSelectedId, setExpandedIds, selectedId } = useTreeContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!externalId) {
      return;
    }

    if (selectedId === externalId) {
      return;
    }

    const node = findNodeById(treeData, auditLog.changeLogLookup, externalId);
    setSelectedId(externalId);

    if (!node) {
      return;
    }

    const newExpanded: string[] = [externalId];
    let current = node.parent;
    while (current) {
      newExpanded.push(current.id);
      current = current.parent;
    }

    setExpandedIds(newExpanded);
  }, [externalId, setSelectedId, setExpandedIds, treeData, auditLog.changeLogLookup, selectedId]);

  const handleSelected = (externalId: string) => {
    navigate(`/locations/${auditLog.locationId}/menus/${auditLog.menuId}/audit-log/${externalId}`);
  };

  const isMenuSelected = selectedId === auditLog.menuId;

  return (
    <div className="flex h-[calc(100vh-4rem)] overflow-auto">
      <aside className="w-1/4 border-r border-grey-lightest overflow-y-auto">
        <SidebarTreeView onSelect={handleSelected} />
      </aside>
      <main className="flex-1 overflow-y-auto p-4">
        {isMenuSelected ? (
          <MenuAuditLogTable auditLogs={auditLog.changeLogs} metadata={auditLog.changeLogLookup} onSelect={handleSelected} />
        ) : (
          <ChangeLogDetailView selectedId={selectedId} auditLog={auditLog} sections={sections} onSelect={handleSelected} />
        )}
      </main>
    </div>
  );
};

type Props = {
  auditLog: AuditLogDto;
  sections?: MenuSectionDto[];
  products?: ProductOverviewDto[];
};
const AuditLog = ({ auditLog, sections, products }: Props) => {
  const { externalId } = useParams<{ externalId: string }>();

  const treeDataWithParents = useMemo(() => {
    const rawTree = buildTreeFromMenuSections(sections || [], products || [], auditLog.menuId);
    return buildTreeWithParentRefs(rawTree);
  }, [sections, auditLog.menuId, products]);

  return (
    <TreeProvider treeData={treeDataWithParents}>
      <AuditLogContent auditLog={auditLog} externalId={externalId} sections={sections} products={products} />
    </TreeProvider>
  );
};
export default AuditLog;
