import { createContext, useContext, useMemo, useRef, useState } from "react";
import { TreeNodeData } from "../components/TreeView/TreeNode";
import React from "react";

type TreeContextType = {
  selectedId: string | null;
  expandedIds: string[];
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  setSelectedId: (id: string) => void;
  setExpandedIds: React.Dispatch<React.SetStateAction<string[]>>;
  treeData: TreeNodeData[];
};

const TreeContext = createContext<TreeContextType | null>(null);

export const useTreeContext = () => {
  const ctx = useContext(TreeContext);
  if (!ctx) throw new Error("TreeContext is not available");
  return ctx;
};

type TreeProviderProps = {
  children: React.ReactNode;
  treeData: TreeNodeData[];
};

const flattenTree = (nodes: TreeNodeData[]): string[] => {
  const result: string[] = [];
  const walk = (n: TreeNodeData[]) => {
    for (const node of n) {
      result.push(node.id);
      if (node.children) walk(node.children);
    }
  };
  walk(nodes);
  return result;
};

export const useStableRefMap = (keys: string[]) => {
  // Use a single ref to hold a map of refs
  const refMap = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  // Only initialize refs for new keys
  useMemo(() => {
    for (const key of keys) {
      if (!refMap.current[key]) {
        refMap.current[key] = React.createRef<HTMLDivElement>();
      }
    }
  }, [keys]);

  return refMap.current;
};

export const TreeProvider = ({ children, treeData }: TreeProviderProps) => {
  const [selectedId, setSelectedId] = useState<string | null>(treeData[0]?.id ?? null);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const handleSelected = (id: string) => {
    setSelectedId(id);
  };

  const allNodeIds = useMemo(() => flattenTree(treeData), [treeData]);
  const refMap = useStableRefMap(allNodeIds);

  return (
    <TreeContext.Provider
      value={{
        selectedId,
        setSelectedId: handleSelected,
        expandedIds,
        setExpandedIds,
        refMap,
        treeData,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};
