import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { ProductOverviewDto } from "types/contracts/contracts";

export const useProducts = () => {
  const { get } = useFetch();
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();

  const getProductsUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/products`;

  const products = useQuery<ProductOverviewDto[], Error>(["products", selectedMenu?.menuId, selectedSection?.sectionId], () => get(getProductsUrl), {
    enabled: !!selectedMenu?.menuId && !!selectedSection?.sectionId,
  });

  return products;
};

export const useAllProducts = () => {
  const { get } = useFetch();
  const { selectedMenu } = useSelectedMenu();
  const getProductsUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/products`;

  const products = useQuery<ProductOverviewDto[], Error>(["all-products", selectedMenu?.menuId], () => get(getProductsUrl), {
    enabled: !!selectedMenu?.menuId,
    staleTime: 1000 * 60 * 30, // 30 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return products;
};
